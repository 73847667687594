<template>
  <default-layout>
    <div class="background-img">
      <div class="lender py-7 mx-7 flex justify-center flex-col">
        <div @click="handleClickInvestWithTings">
          <div
            class="invest-item align-invert py-2 px-3 shadow-3"
            :class="{
              emphasize: taskEmphasize[0],
            }"
          >
            <div class="invest-title ">
              <base-button
                to="#"
                class="normal-case border-16 bg-tertiary text-white px-4 py-2"
              >
                Đầu tư cùng Interloan
              </base-button>
              <span> <b>8%</b> năm </span>
            </div>
            <div class="invest-content">
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div><b>Đồng đầu tư</b> cùng Interloan</div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>Hỗ trợ <b>cash back lên tới 20% giá trị đầu tư</b></div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>
                  <b>Rút trước hạn bất cứ khi nào</b> với tỷ suất sinh lời 2%
                  năm
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="handleClickInvest">
          <div
            class="shadow-1 invest-item py-2 px-3 mt-6"
            :class="{
              emphasize: taskEmphasize[1],
            }"
          >
            <div class="invest-title ">
              <base-button
                to="#"
                class="normal-case mr-2  px-4 py-2 border-16 bg-transparent bg-none border-1 border-primary shadow-none"
              >
                Đầu tư tự chọn
              </base-button>
              <span>
                <span class="invest-title-interest-rate">lên đến </span>
                <b>19%</b> năm
              </span>
            </div>
            <div class="invest-content">
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>
                  Tùy chọn đầu tư khoản ứng lương theo <b>khẩu vị rủi ro</b>
                </div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>Kỳ hạn <b>1 - 6 tháng</b></div>
              </div>
              <div>
                <span class="mr-1">
                  <icon-check-circle class="text-primary" />
                </span>
                <div>
                  <b>Nhận gốc và lãi hàng tháng</b>, khi CBNV thanh toán ứng
                  lương
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import BaseButton from '@/components/Base/BaseButton.vue';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default defineComponent({
  name: 'LenderIntro',
  components: { DefaultLayout, BaseButton, IconCheckCircle },
  setup() {
    const router = useRouter();

    const handleClickInvestWithTings = () => {
      router.push({
        name: ConstRouter.LENDER_INVESTS.name,
      });
    };
    const handleClickInvest = () => {
      router.push({
        name: ConstRouter.LENDER_INVESTS.name,
      });
    };

    return {
      taskEmphasize: [true, false],
      vh: window.innerHeight * 0.01,
      handleClickInvest,
      handleClickInvestWithTings,
    };
  },
});
</script>
<style lang="scss" scoped>
$color: #f1b45b;

.background-img {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.lender {
  height: calc((var(--vh, 1vh) * 100) - (56px));
  //height: calc((var(--vh, 1vh) * 100) - (32px));
  //height: calc((var(--vh, 1vh) * 100) - (60px + 32px));
  @media (max-width: 370px) {
    height: unset;
  }

  .invest-item {
    background: white;
    border-left: 5px solid;
    border-radius: 4px;

    .invest-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 370px) {
        flex-direction: column !important;
        .invest-title-interest-rate {
          position: unset !important;
        }
      }

      span {
        b {
          font-size: 36px;
        }
      }

      span {
        position: relative;

        .invest-title-interest-rate {
          left: 0;
          right: unset;
        }
      }

      .invest-title-interest-rate {
        position: absolute;
        top: -10px;
        right: 0;
      }
    }

    .invest-content {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }
    }

    &.emphasize {
      border-left-color: $color;
      left: 0;
      right: unset;

      .invest-title {
        > span {
          position: relative;

          .invest-title-interest-rate {
            left: 0;
            right: unset;
          }

          b {
            color: $color;
          }
        }
      }
    }

    &.align-invert {
      border-left: none;
      border-right: 5px solid $color;

      .invest-title {
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
