
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import BaseButton from '@/components/Base/BaseButton.vue';
import IconCheckCircle from '@/components/Icon/IconCheckCircle.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default defineComponent({
  name: 'LenderIntro',
  components: { DefaultLayout, BaseButton, IconCheckCircle },
  setup() {
    const router = useRouter();

    const handleClickInvestWithTings = () => {
      router.push({
        name: ConstRouter.LENDER_INVESTS.name,
      });
    };
    const handleClickInvest = () => {
      router.push({
        name: ConstRouter.LENDER_INVESTS.name,
      });
    };

    return {
      taskEmphasize: [true, false],
      vh: window.innerHeight * 0.01,
      handleClickInvest,
      handleClickInvestWithTings,
    };
  },
});
